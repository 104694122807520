// ------------- MEDIA QUERIES ------------- //

//@media (max-width:$x-large-media-query-size) 
$x-large-media-query-size: pxToEm(1600px);	 

//@media (max-width:$large-media-query-size)
$large-media-query-size: pxToEm(1280px);		

//@media (max-width:$medium-media-query-size)
$medium-media-query-size: pxToEm(1024px);

//@media (max-width:$tablet-media-query-size)
$tablet-media-query-size: pxToEm(767px);	

//@media (max-width:$small-media-query-size)
$small-media-query-size: pxToEm(640px);	

//@media (max-width:$mobile-media-query-size)
$mobile-media-query-size: pxToEm(340px); 

// ------------- BREAKPOINTS MAP ------------- //

$breakpoints: ( 
    x-large: $x-large-media-query-size,
    large : $large-media-query-size, 
    medium: $medium-media-query-size, 
    tablet: $tablet-media-query-size, 
    small : $small-media-query-size,
    mobile : $mobile-media-query-size 
);

// ------------- COLORS ------------- //

$primary-medium:            #74cbc8 !default;
$primary-dark: #181818 !default;
$primary-light:             #ffffff !default;
$secondary-medium: #34a39b !default;
$secondary-light:           #eeeeee !default;
$white:                     #ffffff !default;
$black:                     #000000 !default;
$gray-light:                #e3f5f4 !default;
$gray:                      #6a6a6a !default;

$green:                     #a5c800 !default;
$blue:                      #0082d1 !default;

/*SOA VARS*/
$color-primary: orange;

//Color Palette
$primary-blue-1: #024c7b;
$secondary-blue: #76C3D4;
$secondary-teal: #40d0a5;
$primary-red: #d13138;
$primary-yellow: #fdcd06;
$secondary-green: #babf33;
$secondary-orange: #e27e26;
$secondary-grey: #bdbaba;
//to avoid possibly maddening spelling bug
$secondary-gray: #bdbaba;
$tertiary-green: #43B5A3;
$tertiary-navy: #209FB6;
$headline-color: #1e1e1e;
$body-copy-color: #666;
$cta-arrow-bg-hover: #247893;

// Custom Colors
$dark-grey: #656565;
$mid-grey: #ccc;
$off-white: #f2f2f2;
$account-hover: #0b6299;
$light-grey: #f8f8f8;
$dark-icons: #666;
$light-icons: #d4d4d4;
$footer-link-grey: #918a7e;
$grey-border: #ccc;
$grey-dark: #ddd;
$grey-warm: #ececec;
$grey-warm-darker: #E5E5E5;
$icon-blue: #348ac7;
$table-hover-grey: #f5f5f5;
$tab-hover: #3c8dbc;
$module-box-bg: #eef7f9;
$seminar-box-bg: #c8e5ec;
$table-header-grey: #a1a19f;

// Link Colors
$link-default: #0090bc;
$link-hover: #2a6496;
$link-visited: #020f7b;
$link-red: #c71d28;

// Other
$header-text: #333;
$header-text-light: #555;
$filter-header-color: #00497a;
$filter-subheader-color: #0091bb;
$filter-checkbox-text: #3a99fc;
$filter-icon-color: #313033;
$text-muted: #C3C0C0;
$faded-orange: rgb(208, 191, 165);

$grey-opaque: rgba(189,186,186,0.2);

//Other variables
$padding-base: 1.6rem;

//FileType colors
$metro-blue: #2b5797;
$metro-green: #00a300;
$metro-orange: #da532c;

/// Microsite colors
$sky-blue: #77C4D5;
$header-color: rgba($sky-blue, .9);
$microsite-navy:#024D7C;



// ------------- FONTS ------------- //
$body-font: 'Roboto', Arial, sans-serif !default;
$heading-font:  'Roboto', Arial, sans-serif !default;
$accent-font:   'Space Mono', monospace !default;

// ------------- FONT WEIGHTS ------------- //

$font-weight-normal:		400 !default;
$font-weight-bold:			700 !default;

// ------------- CONSTANTS ------------- //
$max-container-width:       120rem;
$gap-lg:                    5rem !default;
$gap:                       2.5rem !default;
$gap-md:                    1.5rem !default;
$gap-sm:                    1rem !default;
$space:                     .5rem !default;
$max-line-length:           70rem !default;
$max-input-width:           30rem !default;
$ui-icon-thickness:         2px !default; //for pseudoelement arrows, close buttons, etc
$border-radius:             3px !default;
$button-size:               3rem !default; //for gallery navs, etc
$ui-icon-size:              .75rem !default;

// ------------- MISCELLANEOUS --------------- //

$prefix:                    'adage-' !default; //prefix for project-specific classes
$transition:                .2s !default;
$shadow:                    .16rem .16rem .6rem rgba($primary-dark, .3);

// ------------- PADDING/MARGIN MAPS --------- //

$standard-padding-map: ( // for global use on most elements. Extend %standard-padding-h or %standard-padding-v
        null       : 3rem,
        medium      : 2rem,
        small       : 1rem
    );

$block-margin-map: ( // for use between blocks. Extend %standard-margin-v
        null        : 6rem,
        large       : 5rem,
        medium      : 4rem,
        small       : 2rem
    );

$gutter-map: (  // for use in anything with columns. Use mixin responsive-property('margin-right', $gutter-map), etc. 
                // DON'T REMOVE ANY BREAKPOINTS.
        null        : 2.25rem,
        large       : 1.5rem,
        medium      : 1.5rem,
        small       : 1.25rem
    );

$card-padding-map: ( // for use with cards or smaller sub-components. Use mixin responsive-property('margin-right', $gutter-map), etc
        null        : 1.5rem,
        large       : 1.25rem,
        small       : 1rem
    );

$giant-padding-map: ( // for use with galleries
        null        : 14rem,
        large       : 10rem,
        medium      : 4rem,
        small       : 3.5rem
    );

$text-spacing-map: ( //spacing between text elements inside a .rich-text container
        null        : 1.5rem,
        medium      : 1rem
    );

$square-icon-size-map: (
        null        : 3rem,
        small       : 2.5rem
    );