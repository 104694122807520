﻿.adage-modal-wrapper {
    &[aria-hidden="true"] {
        visibility: hidden;
        opacity: 0;
    }

    background-color: rgba(0, 0, 0, .15);
    padding: 3.5rem;
    display: flex;
    align-items: center;
    z-index: 150;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 1;
    overflow: auto;

    .adage-modal-dialog {
        position: relative;
        max-width: 50em;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        top: 2.5rem;
    }

    .adage-video {
        &-iframe {
            position: relative;
            padding-bottom: 56.25%;

            iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }

    .adage-modal-overlay {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #252525;
        opacity: .95;

        &:after {
            content: '';
            position: fixed;
            width: 100%;
            height: 100vh;
        }
    }

    .adage-modal-close {
        width: 3rem;
        height: 3rem;
        position: absolute;
        right: -3rem;
        top: -3rem;
        border: 3px solid $white;
        background-color: transparent;
        border-radius: 50%;

        &:before, &:after {
            content: '';
            display: block;
            width: 1.25em;
            height: 0;
            border-style: solid;
            border-color: #fff;
            border-width: 0 0 5px 0;
            position: absolute;
            left: 2px;
            top: calc(50% - 2px);
        }

        &:before {
            transform: rotate(45deg);
        }

        &:after {
            transform: rotate(-45deg);
        }
    }
}

.dialog-container[aria-hidden='true'] {
    display: none;
}

.carousel-iframe[aria-hidden='true'] {
    display: none;
}
