﻿/// <reference path="../../main.scss" />
$border-blue: #022e4a;
$button-blue: #02446e;

.carousel-wrapper {
    background-image: url("/Static/images/bg-graph.png");
    display: flex;

    .carousel {
        &-desktop {
            display: flex;
            padding: 0;
            min-height: 320px;

            @include media(tablet) {
                display: none;
            }

            &-control {
                display: flex;

                &-item {
                    display: flex;
                    flex-direction: column;

                    .swiper-slide { // Hiding slides on desktop
                        display: none;
                    }

                    > div {
                        img, div, a {
                            display: none;
                        }

                        width: 360px;
                        border-left: 1px solid $border-blue;
                        border-right: 1px solid $border-blue;
                        border-bottom: 1px solid $border-blue;
                        background-color: $button-blue;
                        padding: $gap-sm;
                        cursor: pointer;
                        flex: 1 1 auto;
                        display: flex;
                        align-items: center;

                        @include media(medium) {
                            width: 295px;
                        }

                        &.active {
                            background-color: $white;

                            > * {
                                color: $button-blue;
                            }
                        }

                        &:first-child {
                            border-top: 1px solid $border-blue;
                        }

                        .carousel-title {
                            font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen-Sans,Ubuntu,Cantarell,sans-serif;
                            font-size: 1.6rem;
                            line-height: 1.375;
                            font-weight: normal;
                        }

                        > * {
                            color: $white;
                            padding: 0;
                        }

                        &:hover {
                            background-color: $border-blue;

                            > * {
                                color: $white;
                            }
                        }
                    }
                }
            }

            &-cta {
                border-radius: 4px;
                padding: $gap-sm;
                background-color: $white;
                font-weight: 500;
                transition: all .3s ease-in-out;
                border: none;
                color: $primary-blue-1;

                &:hover {
                    background-color: $secondary-blue;
                    color: $primary-dark !important;
                }

                &.hide {
                    display: none;
                }
            }

            &-slide {
                display: flex;
                padding: $gap;
                align-items: flex-end;
                flex: 1 1 auto;
                background-size: cover;
                background-position: center;
            }
        }

        &-mobile {


            @include media-min(tablet) {
                display: none;
            }

            .swiper-slide {
                display: flex;
                justify-content: center;
                text-align: center;
                min-height: 320px;
                align-items: flex-end;
                background-position: center;
                background-size: cover;

                &-inner {
                    margin-bottom: 50px;
                }
            }

            .carousel-desktop-control-item-inner { // hiding desktop controls in mobile
                display: none;
            }

            .swiper-pagination-bullet {
                width: 16px;
                height: 16px;
                opacity: 1;
                background-color: #ffffff;
                border: 1px solid #333E48;


                &-active {
                    &:after {
                        width: 10px;
                        height: 10px;
                        content: '';
                        background-color: $border-blue;
                        display: block;
                        border-radius: 50%;
                        position: relative;
                        top: 2px;
                        left: 2px;
                    }
                }
            }

            &-cta {
                border-radius: 4px;
                padding: $gap-sm;
                background-color: $white;
                color: $border-blue;
                display: inline-block;
                font-weight: 500;
                transition: all .3s ease-in-out;
                border: none;

                &:hover {
                    background-color: $secondary-blue;
                    color: $primary-dark !important;
                }
            }

            &-title {
                background-color: rgba($border-blue, .7);
                padding: $space;
                color: $white;
                margin-bottom: $space*2;
            }
        }

        &-video {
            &-trigger {
                &:before {
                    display: inline-block;
                    height: 12px;
                    width: 12px;
                    border-left: solid 10px $primary-blue-1;
                    border-bottom: solid 6px transparent;
                    border-top: solid 6px transparent;
                    content: " ";
                    margin-right: 3px;
                    box-sizing: border-box;
                }
            }
        }
    }
}
