﻿.maintenance-message {
    border-color: $link-red;

    &-headline {
        display: flex;
        align-items: flex-end;

        > * {
            color: $link-red;
            padding: 0;
        }

        .alert-icon {
            /*width: 4rem;
            height: 4rem;*/
            font-size: 3rem;
            margin-right: 1.5rem;
        }
    }
}
.order-md-last {
    @media (min-width: 768px) {
        -ms-flex-order: 13;
        order: 13;
    }
}

