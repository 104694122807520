﻿/// <reference path="../../main" / >

@mixin epimode {
    .epi-editmode {
        @content;
    }
}

[data-epi-property-name] {
    min-height: auto !important;
}

.epi-pinnableContentWrapper *, .epi-pinnableContentWrapper *:before, .epi-pinnableContentWrapper *:after {
    box-sizing: initial;
}