﻿/// <reference path="../../main.scss" />
/// Simplified media queries
/// @access public
/// @name media
/// @content true
/// @param {string | value} $breakpoint - Value of breakpoint; accepts string values defined in breakpoints map
/// @example
/// header {
///   @include media(small) {
///        height: auto;
///   }
/// }
/// @group Miscellaneous
@mixin media($breakpoint, $breakpoints-map: $breakpoints) {
    @if map-has-key($breakpoints-map, $breakpoint) {
        $breakpoint: map-get($breakpoints-map, $breakpoint);
    }

    @media (max-width:$breakpoint) {
        @content;
    }
}

@mixin media-min($breakpoint, $breakpoints-map: $breakpoints) {
    @if map-has-key($breakpoints-map, $breakpoint) {
        $breakpoint: map-get($breakpoints-map, $breakpoint);
    }

    @media (min-width:$breakpoint + .01em) {
        @content;
    }
}

@mixin media-range($breakpoint-min, $breakpoint-max, $breakpoints-map: $breakpoints) {
    @if map-has-key($breakpoints-map, $breakpoint-min) {
        $breakpoint-min: map-get($breakpoints-map, $breakpoint-min);
    }

    @if map-has-key($breakpoints-map, $breakpoint-max) {
        $breakpoint-max: map-get($breakpoints-map, $breakpoint-max);
    }

    @media (min-width: $breakpoint-min + .01em) and (max-width: $breakpoint-max) {
        @content;
    }
}
